//const fbq=window.fbq;

//add to cart
export function FBP_addToCart(product, qty) {
	window.fbq("track", "AddToCart", {
		content_ids: [product.id],
		content_name: product.name,
		content_type: "product",
		contents: [{ id: product.id, quantity: qty }],
		currency: "USD",
		value: product.salePrice,
	});
}

// begin checkout
export function FBP_initiateCheckout(cartlist) {
	window.fbq("track", "InitiateCheckout", {
		content_category: "---",
		content_type: "product",
		content_ids: cartlist && cartlist.map((item, index) => item.id),
		contents:
			cartlist &&
			cartlist.map((item, index) => {
				return {
					id: item.id,
					name: item.name,
					list_name: "----",
					brand: cartlist.data.items[index].brand_id,
					category: item.main_category,
					variant: item.condition,
					list_position: 1,
					quantity: item.qty,
					price: item.salePrice,
				};
			}),
		num_items: cartlist.length,
		currency: "USD",
		value: "medium",
	});
}

//purchase

export function FBP_purchase(data) {
	window.fbq("track", "Purchase", {
		content_ids: data && data.data && data.data.order_items.map((item, index) => item.item_id),
		contents:
			data &&
			data.data &&
			data.data.order_items.map((item, index) => {
				return {
					id: item.item_id,
					name: item.name,
					list_name: "----",
					brand: data.data.items[index].brand_id,
					category: data.data.items[index].category_id,
					variant: "Black",
					list_position: 1,
					quantity: item.qty,
					price: item.price,
				};
			}),
		content_type: "product",
		num_items: data.data.order_items.length,
		value: data && data.data && data.data.total_amount,
		currency: "USD",
	});
}
export function FBP_addToWishlist(data) {
	window.fbq("track", "AddToWishlist", {
		content_category: "---",
	});
}
