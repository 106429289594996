import React, { useEffect, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import LoadingOverlay from "../components/features/loading-overlay";
import { scrollTop } from "../utils";
import SEO from "../components/features/seo";
import Layout from "../components/app";
import PAGE404 from "../components/pages/others/404";

const ProductPages = lazy(() => import("./products-route.js"));
const ShopPages = lazy(() => import("./shop-route.js"));
const HomePage = lazy(() => import("./home-route.js"));
const BrokerPage = lazy(() => import("./broker-route.js"));

const AppRoot = () => {
	useEffect(() => {
		scrollTop();
	}, []);
	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<SEO title={document.location.href} />
			<Layout>
				<Switch>
					<Route
						path={`${process.env.PUBLIC_URL}/product`}
						component={(props) => <ProductPages {...props} />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shop/`}
						component={(props) => <ShopPages {...props} />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/trader/`}
						component={(props) => <BrokerPage {...props} />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/`}
						component={(props) => <HomePage {...props} />}
					/>
					<Route component={PAGE404} />
				</Switch>
			</Layout>
		</React.Suspense>
	);
};

export default AppRoot;
