import { WHITE_SPACES } from "../constants/regex";
import { APP_VERSION, CONSOLE_LOG, IMAGES_BASE_URL } from "../config";
import moment from "moment";
import React from "react";
import { US_STATES } from "../constants/states";
// utils to initialize other funcs
export const initFunctions = () => {
	definePolyfills();
};

/**
 * definePolyfills
 */
export const definePolyfills = () => {
	if (typeof Object.values != "function") {
		Object.defineProperty(Object, "values", {
			value: function values(obj) {
				if (obj === null) {
					throw new TypeError("Cannot convert undefined or null to object");
				}

				let res = [];

				Object.keys(obj).map(function (key) {
					res.push(obj[key]);
					return 1;
				});

				return res;
			},
		});
	}

	if (window.Element && !Element.prototype.closest) {
		Element.prototype.closest = function (s) {
			var matches = (this.document || this.ownerDocument).querySelectorAll(s),
				i,
				el = this;

			do {
				i = matches.length;
				while (--i >= 0 && matches.item(i) !== el) {}
			} while (i < 0 && (el = el.parentElement));
			return el;
		};
	}

	if (!Element.prototype.index) {
		Element.prototype.index = function (s) {
			let self = this;
			let children = self.parentElement.children;
			for (let i = 0; i < children.length; i++) {
				if (self === children[i]) return i;
			}
			return 0;
		};
	}
};

export const commify = (value) => {
	if (value) {
		var parts = value.toString().split(".");
		const numberPart = parts[0];
		const decimalPart = parts[1];
		const thousands = /\B(?=(\d{3})+(?!\d))/g;
		return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
	}
};
/**
 * refresh all settings.
 */
export const initSettings = function () {
	scrollTop();
	// mobileMenu();
};

/**
 * add event listner to anchors of header top to prevent Default
 */
export function preventHeaderDefault() {
	let items = document.querySelectorAll(".header-dropdown a, .top-menu li a");
	for (let i = 0; i < items.length; i++) {
		items[i].addEventListener("click", preventDefaultHandler);
	}
}

/**
 * add event listener to anchors of product to prevent default.
 */
export function preventProductDefault() {
	let items = document.querySelectorAll(".product-nav a, .product-size a");
	for (let i = 0; i < items.length; i++) {
		items[i].addEventListener("click", preventDefaultHandler);
	}
}

export function removePreventProductDefault() {
	let items = document.querySelectorAll(".product-nav a, .product-size a");

	for (let i = 0; i < items.length; i++) {
		items[i].addEventListener("click", preventDefaultHandler);
	}
}

function preventDefaultHandler(e) {
	e.preventDefault();
}

/**
 * Apply sticky header
 */
export const stickyHeaderHandler = function () {
	let top = document.querySelector("main") ? document.querySelector("main").offsetTop : 300;

	let stickyHeader = document.querySelector(".sticky-header");
	let height = 0;

	if (stickyHeader) {
		height = stickyHeader.offsetHeight;
	}

	if (window.pageYOffset >= top && window.outerWidth >= 992) {
		if (stickyHeader) {
			stickyHeader.classList.add("fixed");
			if (!document.querySelector(".sticky-wrapper")) {
				let newNode = document.createElement("div");
				newNode.className = "sticky-wrapper";
				stickyHeader.parentNode.insertBefore(newNode, stickyHeader);
				document.querySelector(".sticky-wrapper").insertAdjacentElement("beforeend", stickyHeader);
				document.querySelector(".sticky-wrapper").setAttribute("style", "height: " + height + "px");
			}

			if (!document.querySelector(".sticky-wrapper").getAttribute("style")) {
				document.querySelector(".sticky-wrapper").setAttribute("style", "height: " + height + "px");
			}
		}
	} else {
		if (stickyHeader) {
			stickyHeader.classList.remove("fixed");
		}

		if (document.querySelector(".sticky-wrapper")) {
			document.querySelector(".sticky-wrapper").removeAttribute("style");
		}
	}

	if (window.outerWidth < 992 && document.querySelector(".sticky-wrapper")) {
		document.querySelector(".sticky-wrapper").style.height = "auto";
	}
};

/**
 * utils to handle sticky content
 */
let stickyContent, top, bottom, offHeight, originHeight, originWidth;

export const setStickyValues = function (height = 82) {
	if (isIEBrowser()) {
		stickyContent = document.querySelector(".sticky-content");
		if (!stickyContent) return;
		offHeight = height;
		originHeight = stickyContent.offsetHeight;
		stickyContent.style.position = "relative";
		stickyContent.style.top = "0";
	}
};

/**
 * utils to get width
 */
function getWidth(self) {
	return (
		parseInt(self.clientWidth) -
		parseInt(window.getComputedStyle(self).getPropertyValue("padding-left")) -
		parseInt(window.getComputedStyle(self).getPropertyValue("padding-right"))
	);
}

/**
 * sticky content event listener
 */
export const stickyContentHandle = () => {
	if (isIEBrowser()) {
		bottom = stickyContent.parentElement.getBoundingClientRect().bottom + window.pageYOffset;
		top = stickyContent.parentNode.getBoundingClientRect().top + window.pageYOffset;
		originWidth = getWidth(stickyContent.parentElement);

		if (document.querySelector("body").clientWidth < 1024) {
			stickyContent.style.position = "static";
			stickyContent.style.width = "unset";
		} else {
			if (top > window.pageYOffset + offHeight) {
				stickyContent.style.position = "relative";
				stickyContent.style.top = "0";
				stickyContent.style.width = originWidth + "px";
			}

			if (top < window.pageYOffset + offHeight) {
				stickyContent.style.position = "fixed";
				stickyContent.style.top = offHeight + "px";
				stickyContent.style.width = originWidth + "px";
			}
			if (bottom - originHeight - offHeight < window.pageYOffset) {
				stickyContent.style.position = "absolute";
				stickyContent.style.width = originWidth + "px";
				stickyContent.style.bottom = "0";
				stickyContent.style.top = "auto";
			}
		}
	}
};

/**
 * utils to scroll element to top
 */
export const scrollTop = function () {
	let scrollTop = document.querySelector("#scroll-top");
	document.addEventListener(
		"scroll",
		function () {
			if (window.pageYOffset >= 400) {
				scrollTop.classList.add("show");
			} else {
				scrollTop.classList.remove("show");
			}
		},
		false
	);

	scrollTop.addEventListener("click", function (e) {
		if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
			let pos = window.pageYOffset;
			let timerId = setInterval(() => {
				if (pos <= 0) clearInterval(timerId);
				window.scrollBy(0, -120);
				pos -= 120;
			}, 1);
		} else {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
		e.preventDefault();
	});
};

/**
 * utils to scroll element to target element
 */
export const scrollToElement = (e) => {
	let top =
		document.querySelector(e.currentTarget.getAttribute("data-target")).getBoundingClientRect().top +
		window.pageYOffset;
	if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
		let pos = window.pageYOffset;
		let timerId = setInterval(() => {
			if (pos >= top) clearInterval(timerId);
			window.scrollBy(0, 50);
			pos += 50;
		}, 1);
	} else {
		window.scrollTo({
			top: top,
			behavior: "smooth",
		});
	}
	e.preventDefault();
};

/**
 * utils to make mobile menu
 */
export const mobileMenu = function (e) {
	let mobileClose = document.querySelector(".mobile-menu-close");
	mobileClose &&
		mobileClose.addEventListener("click", function (e) {
			mobileMenu && mobileMenu.classList.remove("mmenu-active");
		});

	let overLay = document.querySelector(".mobile-menu-overlay");
	overLay.addEventListener("click", function (e) {
		mobileMenu.classList.remove("mmenu-active");
	});

	let items = document.querySelector(".mobile-menu").querySelectorAll("li");

	for (let i = 0; i < items.length; i++) {
		let item = items[i];
		if (item.querySelector("ul")) {
			let span = document.createElement("span");
			span.className = "mmenu-btn";
			item.querySelector("a").appendChild(span);
		}

		item.addEventListener("click", function () {
			if (mobileMenu.classList.contains("mmenu-active")) {
				mobileMenu.classList.remove("mmenu-active");
			}
		});
	}

	items = document.querySelectorAll(".mmenu-btn");
	for (let i = 0; i < items.length; i++) {
		let item = items[i];

		item.addEventListener("click", function (e) {
			let parent = item.parentElement.parentElement;
			let targetUI = parent.querySelector("ul");
			targetUI.setAttribute("style", "display: block; visibility: hidden;");

			let targetHeight = targetUI.offsetHeight;
			let delta = targetHeight / 60;

			if (isIEBrowser() || isEdgeBrowser()) {
				delta = targetHeight / 30;
			}

			if (!parent.classList.contains("open")) {
				let height = 0;
				let timerID = setInterval(() => {
					if (targetHeight <= height) {
						targetUI.removeAttribute("style");
						targetUI.setAttribute("style", "display: block;");
						clearInterval(timerID);
						return null;
					}

					targetUI.setAttribute("style", "display: block; overflow: hidden; height: " + height + "px");
					height += delta;
				}, 1);

				parent.classList.add("open");
			} else {
				let height = targetHeight;
				let timerID = setInterval(() => {
					if (height <= 0) {
						targetUI.removeAttribute("style");
						targetUI.setAttribute("style", "display: none;");
						clearInterval(timerID);
						return null;
					}

					targetUI.setAttribute("style", "display: block; overflow: hidden; height: " + height + "px");
					height -= delta;
				}, 1);

				parent.classList.remove("open");
			}

			e.stopPropagation();
			e.preventDefault();
		});
	}
};

/**
 * utils to make background parallax
 */
export const parallax = () => {
	let parallax = document.querySelectorAll(".bg-parallax");

	for (let i = 0; i < parallax.length; i++) {
		let y = 0;
		if (parallax[i].classList.contains("header-parallax")) {
			y = ((10 - window.pageYOffset) * 47) / 900 + 50;
		} else {
			y = ((parallax[i].offsetTop - window.pageYOffset) * 47) / parallax[i].offsetTop + 50;
		}

		parallax[i].style.backgroundPositionY = y + "%";
	}
};

/**
 * utils to remove all XSS  attacks potential
 * @param {String} html
 * @return {Object}
 */
export const safeContent = (html) => {
	const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

	//Removing the <script> tags
	while (SCRIPT_REGEX.test(html)) {
		html = html.replace(SCRIPT_REGEX, "");
	}
	//Removing all events from tags...
	html = html.replace(/ on\w+="[^"]*"/g, "");
	return {
		__html: html,
	};
};

/**
 * utils to handle hover event in product
 */
export const hoverIntent = function () {
	let items = document.querySelectorAll(".product-3");

	for (let i = 0; i < items.length; i++) {
		let item = items[i];

		item.addEventListener("mouseover", mouseOverHandler, false);
		item.addEventListener("mouseleave", mouseLeaveHandler, false);

		function mouseOverHandler() {
			let animDiff =
				item.offsetHeight -
				(item.querySelector(".product-body").offsetHeight + item.querySelector(".product-media").offsetHeight);
			let animDistance = item.querySelector(".product-footer").offsetHeight - animDiff;
			item.querySelector(".product-footer").setAttribute("style", "visibility: visible; transform: translateY(0);");
			item.querySelector(".product-body").setAttribute("style", "transform: translateY(" + -animDistance + "px)");
		}

		function mouseLeaveHandler() {
			item.querySelector(".product-footer").setAttribute("style", "visibility: hidden; transform: translateY(100%);");
			item.querySelector(".product-body").setAttribute("style", "transform: translateY(0)");
		}
	}
};

/**
 * utils to make quanity input in product page
 */
export const quantityInputs = () => {
	let items = document.querySelectorAll("input[type='number']");
	for (let i = 0; i < items.length; i++) {
		let item = items[i];

		if ("none" !== item.style.display) {
			let min = item.getAttribute("min"),
				max = item.getAttribute("max"),
				step = item.getAttribute("step"),
				element = document.createElement("div");

			element.className = "input-group input-spinner";
			element.innerHTML =
				'<div className="input-group-prepend"><button style="min-width: 30px" className="btn btn-decrement btn-spinner" type="button"><i className="fas fa-minus"></i></button></div><input type="text" style="text-align: center" className="form-control " required placeholder=""><div className="input-group-append"><button style="min-width: 30px" className="btn btn-increment btn-spinner" type="button"><i className="fas fa-plus"></i></button></div>';
			item.insertAdjacentElement("afterEnd", element);
			item.style.display = "none";

			let inputNumber = element.querySelector("input[type='text']");
			inputNumber.value = item.value;
			if (element) {
				element.querySelector(".btn-decrement").addEventListener("click", decrementHandler);

				element.querySelector(".btn-increment").addEventListener("click", incrementHandler);
			}
			function decrementHandler() {
				if (min <= parseInt(inputNumber.value) - parseInt(step)) {
					inputNumber.value = parseInt(inputNumber.value) - parseInt(step);
					item.value = inputNumber.value;
					item.setAttribute("value", inputNumber.value);
				}
			}

			function incrementHandler() {
				if (max >= parseInt(inputNumber.value) + parseInt(step)) {
					inputNumber.value = parseInt(inputNumber.value) + parseInt(step);
					item.value = inputNumber.value;
					item.setAttribute("value", inputNumber.value);
				}
			}
		}
	}
};

/**
 * utils to detect IE browser
 * @return {bool}
 */
export const isIEBrowser = function () {
	let sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Trident") > -1) return true;
	return false;
};

/**
 * utils to detect safari browser
 * @return {bool}
 */
export const isSafariBrowser = function () {
	let sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Safari") !== -1 && sUsrAg.indexOf("Chrome") === -1) return true;
	return false;
};

/**
 * utils to detect Edge browser
 * @return {bool}
 */
export const isEdgeBrowser = function () {
	let sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Edge") > -1) return true;
	return false;
};

/**
 * find index of first matched string
 */
export const findIndex = function (array, cb) {
	if (array) {
		for (let i = 0; i < array.length; i++) {
			if (true === cb(array[i])) return i;
		}
	}
	return -1;
};

/**
 * utils to set countto in about-2
 */
export const countTo = function () {
	let items = document.querySelectorAll(".count");

	if (items) {
		for (let i = 0; i < items.length; i++) {
			let item = items[i];
			let amount = parseInt(item.getAttribute("data-to"), 10) - parseInt(item.getAttribute("data-from"), 10);
			let time = parseInt(item.getAttribute("data-speed"), 10);
			let interval = parseInt(item.getAttribute("data-refresh-interval"), 10);
			let pt = 0;
			let height = item.parentElement.parentElement.parentElement.offsetTop;
			let flag = 0;

			document.addEventListener("scroll", countToScrollHandler, true);
			function countToScrollHandler() {
				if (pt <= time && height >= window.pageYOffset) {
					if (0 === flag) {
						let timerId = setInterval(() => {
							if (pt >= time) {
								clearInterval(timerId);
							}

							item.innerHTML = parseInt((pt * amount) / time);
							pt = pt + interval;
						}, interval);
					}

					flag = 1;
				}
			}
		}
	}
};

/**
 * utils to set Masonry / Grid layout
 *
 * @param {Object} isotope
 * @param {Function} imagesLoaded
 * @param {String} container
 * @param {String} selector
 * @param {String} filterNav
 */
export function isotopeLoad(isotope, imagesLoaded, container, selector, filterNav, layoutMode = "masonry") {
	let items = document.querySelectorAll(container);

	for (let i = 0; i < items.length; i++) {
		let item = items[i];

		let iso = new isotope(items[i], {
			itemSelector: selector,
			layoutMode: layoutMode,
			filter: document.querySelector(filterNav + " .active")
				? document.querySelector(filterNav + " .active").getAttribute("data-filter")
				: "",
		});

		let imgLoad = imagesLoaded(item, { background: true });
		imgLoad.on("done", function (instance, image) {
			iso.layout();
		});

		if (filterNav) {
			let tabList = document.querySelectorAll(filterNav + " a");

			for (let i = 0; i < tabList.length; i++) {
				tabList[i].addEventListener("click", function (e) {
					e.preventDefault();

					let filterValue = e.currentTarget.getAttribute("data-filter");
					iso.arrange({
						filter: filterValue,
					});

					// add active class
					for (let j = 0; j < tabList.length; j++) {
						if (tabList[j].getAttribute("data-filter") === filterValue) {
							tabList[j].parentNode.classList.add("active");
						} else {
							tabList[j].parentNode.classList.remove("active");
						}
					}
				});
			}
		}
	}
}

/**
 * utils to set Single Product Gallery
 */
export const productGallery = () => {
	let items = document.querySelectorAll(".product-gallery-item");

	for (let i = 0; i < items.length; i++) {
		let item = items[i];

		item.addEventListener("click", productGalleryClickHandler);

		function productGalleryClickHandler(e) {
			for (let i = 0; i < items.length; i++) items[i].classList.remove("active");

			item.classList.add("active");

			let images = document.querySelectorAll(".product-main-image img");

			images[0].setAttribute("src", item.getAttribute("data-image"));
			images[1].setAttribute("src", item.getAttribute("data-zoom-image"));
			document.querySelector(".product-main-image").setAttribute("index", i);
			e.preventDefault();
		}
	}
};

/**
 * set and get from local storage async
 */
export const asyncLocalStorage = {
	setItem: (key, value) =>
		Promise.resolve().then(function () {
			localStorage.setItem(key, value);
		}),
	getItem: (key) =>
		Promise.resolve().then(function () {
			return localStorage.getItem(key);
		}),
};

/**
 * set and get from local storage async
 */
export const datetimestring = {
	yyyymmdd: () => {
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();

		return `${yyyy}-${mm}-${dd}`;
	},
};
export const ddmmyyyy_custom = (date) => {
	const today = new Date(date);
	const dd = today.toLocaleString("en-US", { day: "numeric" });
	const mm = today.toLocaleString("en-US", { month: "numeric" });
	const yyyy = today.toLocaleString("en-US", { year: "numeric" });
	const time = today.toLocaleString("en-US", { hour: "numeric", minute: "numeric" });
	return `${mm}-${dd}-${yyyy} ${time}`;
};

// to convert date format
export const dateFormatter = (date) => {
	var dateFormat = new Date(date).toLocaleDateString("en-us", {
		weekday: "long",
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	});
	return dateFormat;
};

export const stringCapitalize = (word) => {
	if (word === "alphabetically, a-z") {
		let string = "Alphabetically, A-Z";
		return string;
	}
	if (word === "alphabetically, z-a") {
		let string = "Alphabetically, Z-A";
		return string;
	}
	let string = word.toLowerCase().replace("_", " ");

	const split = string.split(" ");

	for (var i = 0; i < split.length; i++) {
		split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
	}

	string = split.join(" ");
	return string;
};

// Capitalize the first letter of each word in a string
export const eFirstLetterCapitalize = (getString) => {
	if (getString) {
		let string = getString;
		string = string.includes("_") ? string.replace(/_/g, " ").split(" ") : string.split(" ");
		for (var i = 0; i < string.length; i++) {
			string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
		}
		string = string.join(" ");

		return string;
	}
};

export const stringToUpperCase = (word) => {
	return word.toUpperCase().replace(" ", "_");
};
export const removeUnderScore = (word) => {
	return word && word.toUpperCase().replace("_", "  ");
};

export const stringCaptilizeAndRemoveMinus = (string) =>
	string && string.replace(/-/g, "  ").replace(/(^\w|\s\w)/g, (firstLetter) => firstLetter.toUpperCase());

export function rendomKey() {
	let dt = new Date().getTime();
	let uuidcreate = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		let r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuidcreate;
}

export const getQueryParmValue = () => {
	const useQuery = new URLSearchParams(document.location.search);
	return useQuery;
};

export const replaceSpaceAndSlasForSubCat = (string) =>
	string.toString().replace(WHITE_SPACES, "-").replace(new RegExp("/", "g"), "!").toLowerCase();

export const replaceSpaceAndSlasForCat = (string) => string.toString().replace(WHITE_SPACES, "-");

export const phoneFormatter = (value) => {
	let output = "";
	value &&
		value.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
			if (g1.length) {
				output = "(";
				output += g1;
				if (g1.length === 3) {
					if (g2.length) {
						output += ")";
						output += " " + g2;
						if (g2.length === 3) {
							if (g3.length) {
								output += "-";
								output += g3;
							}
						}
					}
				}
			}
		});
	return output;
};

export const yyyymmdd = (date, format = "MMM/D/YYYY") => {
	date = date.replace("T", " ");
	date = date.substring(0, date.indexOf("."));
	date = new Date(date);
	return moment(date).format(format);
};

export const timeFormat = (date, format = "YYYY/MM/DD") => {
	return moment(date).format(format);
};

export const log = (string, dump = "", ...rest) => {
	return CONSOLE_LOG && console.log(string, dump && dump, Object.keys(rest).length > 0 ? rest : "");
};

export const addAltForChatImages = () => {
	let container = null;
	// the following logic is use because demandhub chat have missing alt image teg ad we add dynamic
	let intervalId = setInterval(function () {
		container = document.getElementById("demandhub-web-chat-root");
		if (container) {
			let imgs = container.querySelectorAll("img");
			for (let img of imgs) {
				let imgSrc = img.src;
				img.alt = imgSrc && (imgSrc.split("/").pop().split(".")[0] || "").split("-").join(" ");
			}
			// do something with the element
			imgs && imgs.length >= 3 && clearInterval(intervalId);
		}
	}, 250);
};

export const addHttpWithAnchorHref = (data, productTitle) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(data, "text/html");
	const anchors = doc.querySelectorAll("a");
	const images = doc.querySelectorAll("img");

	anchors.forEach((anchor) => {
		const href = anchor.getAttribute("href");
		if (href && !href.startsWith("http")) {
			anchor.setAttribute("href", "https://" + href);
			anchor.setAttribute("target", "_blank");
		} else {
			anchor.setAttribute("target", "_blank");
		}
	});

	images.forEach((image, index) => {
		// Check if the img tag already has an alt attribute
		if (!image.hasAttribute("alt")) {
			const productName = productTitle || "Product"; // Replace with your actual product name
			const altText = `${productName} Detail Image ${index + 1}`;
			image.setAttribute("alt", altText);
		}
	});

	if (data) {
		return doc.documentElement.outerHTML;
	} else {
		return data;
	}
};

export function convertToUrl(text) {
	// Replace spaces with %20, dashes with %2D, and slashes with %2F
	return encodeURIComponent(text.replace(/ /g, "~S").replace(/-/g, "~D").replace(/\//g, "~F")).toLocaleLowerCase();
}
export function urlToText(url) {
	var decodedUrl = decodeURIComponent(url.replace(/\+/g, " "));
	var originalText = decodedUrl.replace(/~S/g, " ").replace(/~D/g, "-").replace(/~F/g, "/");
	return originalText;
}
export const isWholeNumber = (number) => {
	return /^\d+$/.test(number);
};

export const brandURL = function (name) {
	let brand =
		`${process.env.PUBLIC_URL}/shop/brands/${name
			.replace(new RegExp("-", "g"), "+")
			.replace(new RegExp(" ", "g"), "-")
			.toLowerCase()}` || `#`;
	return brand;
};
export const RoundUpToWhole = (value) => {
	if (value) {
		return Math.round(value);
	}
};
export const resolution = {
	productThumbnails: "/300x300/",
	detailPageImages: "/685x450/",
	storeFrontImages: "/1600x675/",
};

export const addResImagesHandler = (images, type) => {
	if (type === "detail") {
		if (images) {
			const splitImage = images.split("/");
			return `${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.detailPageImages}${splitImage[2]}`;
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "product") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "storeFrontListing") {
		if (images) {
			const splitImage = images.split("/");
			return `${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`;
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/products/shop/large-1.jpg`;
		}
	}
	if (type === "storeFrontProject") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}

	if (type === "storeFrontDetail") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.storeFrontImages}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}

	if (type === "storeFrontProfile") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "productEight") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "productSix") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "search") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "productDetailSwiperSlider") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}

	if (type === "cart") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "cartView") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "wishList") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "quickView") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.detailPageImages}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "quickViewSlider") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "checkout") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
	if (type === "mainMenu") {
		if (images) {
			const splitImage = images.split("/");
			return (
				images.length > 0 &&
				`${IMAGES_BASE_URL}${splitImage[0]}/${splitImage[1]}${resolution.productThumbnails}${splitImage[2]}`
			);
		}
		if (!images) {
			return `${process.env.PUBLIC_URL}/assets/images/home/products/product-1-2.jpg`;
		}
	}
};

export function modifyBrokerUrl(mainString, subString) {
	if (mainString.includes(subString)) {
		// Remove the substring from the mainString
		mainString = "";
	} else {
		// Add the substring to the end of the mainString
		mainString = subString;
	}
	return mainString;
}

export function validateAndReturnItem(inputString) {
	if (typeof inputString !== "string" || inputString.trim() === "") {
		return [];
	}
	var cleanedString = inputString.replace(/[^A-Za-z\s]+/g, "");
	if (cleanedString === "") {
		return [];
	}
	return [cleanedString];
}

export const extractVideoId = (description) => {
	if (description) {
		// Regular expression to match the video elements in the string
		const regex = /<figure class="media"><oembed url="(.*?)"><\/oembed><\/figure>/g;
		// Function to replace oembed with iframe
		const replaceOembedWithIframe = (videoUrl) => {
			if (videoUrl) {
				const videoId = videoUrl.match(
					/(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
				)[1];
				return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
			}
		};
		// Replace oembed elements with iframe elements and embed other YouTube URLs
		const addIframeAndEmbededString = description.replace(regex, (videoLink) => {
			return replaceOembedWithIframe(videoLink);
		});
		return addIframeAndEmbededString;
	}
};

export const getProductShipping = (item) => {
	let ship = [];
	if (item.shipping_type === "fedex") {
		ship.push("FEDEX");
	}
	if (item.shipping_type === "fedex_or_local_pickup") {
		ship.push("FEDEX");
		ship.push("LOCAL_PICKUP");
	}
	if (item.shipping_type === "free_shipping") {
		ship.push("FREE_SHIPPING");
	}
	if (item.shipping_type === "local_pickup_only") {
		ship.push("LOCAL_PICKUP");
	}
	if (item.shipping_type === "estimated_shipping") {
		ship.push("CALCULATED");
	}
	return (ship = ship.map((text) => text));
};
export const getProductLocation = (locations, shippingType) => {
	let location = "";
	if (shippingType === "LOCAL_PICKUP" || shippingType === "FREE_SHIPPING") {
		(locations || []).forEach((loc, index) => {
			let qoh = loc.location_qoh && loc.location_qoh.qoh;
			let qoh_prev = loc[index - 1] && loc[index - 1].location_qoh && loc[index - 1].location_qoh.qoh;
			if (!(qoh < qoh_prev)) {
				location = `${loc.city}, ${
					US_STATES.filter((xState) => xState.id === loc.state) &&
					US_STATES.filter((xState) => xState.id === loc.state)[0] &&
					US_STATES.filter((xState) => xState.id === loc.state)[0].name
				}`;
			}
		});
		return (
			<span className="d-block">
				{shippingType === "FREE_SHIPPING" && "Shipped from:"}
				{shippingType === "LOCAL_PICKUP" && (
					<span>
						<span className="text-primary font-weight-bold">FREE</span> Pickup Location:
					</span>
				)}
				<strong> {location}</strong>
			</span>
		);
	}
	return;
};

export const sortedImages = (images) => {
	let sort = images.sort((a, b) => {
		// Check if orderofimage exists
		if (a.orderofimage && b.orderofimage) {
			return a.orderofimage - b.orderofimage; // Sort by orderofimage property
		}
		// If orderofimage is missing, maintain the current order
		return 0;
	});
	return sort;
};

export const sortAlphabatically = (value) => {
	if (value) {
		const sort = value.sort((a, b) => (a.name > b.name ? 1 : -1));
		return sort;
	}
};

export const checkVersion = async () => {
	try {
		const storedVersion = localStorage.getItem("AppVersion");
		if (storedVersion !== APP_VERSION) {
			console.log("New version available! Page is Reloaded");
			localStorage.setItem("AppVersion", APP_VERSION);
			// Force hard reload
			window.location.href = window.location.href + "?cachebuster=" + new Date().getTime();
		} else {
			console.log("AppVersion =", storedVersion);
		}
	} catch (error) {
		console.error("Error checking version:", error);
	}
};
export const replaceH1WithDiv = (htmlContent) => {
	// Parse the HTML content using DOMParser
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlContent, "text/html");

	const h1Elements = doc.querySelectorAll("h1");
	h1Elements.forEach((h1) => {
		const spanElement = document.createElement("div");
		spanElement.classList.add("h2");
		spanElement.innerHTML = h1.innerHTML;
		// Copy the content of <h1> to <span>
		h1.replaceWith(spanElement); // Replace <h1> with <span>
	});

	// Return the updated HTML as a string
	return doc.body.innerHTML;
};
